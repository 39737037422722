import React, { useState } from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import sep from './separator-vertical.png'

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const initialLayouts = {
  Monday: [],
  Tuesday: [],
  Wednesday: [],
  Thursday: [],
  Friday: [],
  Saturday: [],
  Sunday: []
};

const rowHeight = 70; // Each row represents 70 pixels
const gapHeight = rowHeight + 20;

const MyGridLayout = () => {
  const [layouts, setLayouts] = useState(initialLayouts);
  const [temperatureInputs, setTemperatureInputs] = useState({});
  const [showDropdown, setShowDropdown] = useState(null);
  const [copyTargetDay, setCopyTargetDay] = useState('');
  const [checked, setChecked] = useState(false);
  const [isResizingOrDragging, setIsResizingOrDragging] = useState(false);

  const onLayoutChange = (day, newLayout) => {
    setLayouts((prevLayouts) => {
      const layout = prevLayouts[day];
      newLayout.forEach((newBox) => {
        const existingBox = layout.find((box) => box.i === newBox.i);
        if (existingBox) {
          newBox.temperature = existingBox.temperature; // Preserve temperature value
        }
      });
      return { ...prevLayouts, [day]: newLayout };
    });
  };

  const handleContainerClick = (event) => {
    if (isResizingOrDragging) {
      // Ignore the click if a resize or drag event is in progress
      return;
    }

    if (event.target.closest('.box')) {
      // If the click happened inside a box, do nothing
      return;
    }

    const container = event.currentTarget;
    const rect = container.getBoundingClientRect();
    const xPosition = event.clientX - rect.left;
    const yPosition = (event.clientY - rect.top + container.scrollTop) / (rowHeight + 10);
    const rowIndex = Math.floor(yPosition);
    if (rowIndex >= 24) return; // Ignore clicks below the 24th row
    const dayIndex = Math.floor(xPosition / (rect.width / daysOfWeek.length));
    const day = daysOfWeek[dayIndex];
    const newBoxId = `box-${day}-${layouts[day].length + 1}`;
    const newBoxLayout = { i: newBoxId, x: 0, y: rowIndex, w: 1, h: 1, minW: 1, maxW: 2, minH: 1, maxH: 24, temperature: null };

    setLayouts((prevLayouts) => ({
      ...prevLayouts,
      [day]: [...prevLayouts[day], newBoxLayout]
    }));
    setTemperatureInputs((prevInputs) => ({
      ...prevInputs,
      [newBoxId]: '' // Initialize temperature input for the new box
    }));
  };

  const handleTemperatureChange = (event, boxId) => {
    setTemperatureInputs((prevInputs) => ({
      ...prevInputs,
      [boxId]: event.target.value
    }));
  };

  const handleTemperatureKeyPress = (event, boxId, day) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setLayouts((prevLayouts) => ({
        ...prevLayouts,
        [day]: prevLayouts[day].map((box) =>
          box.i === boxId ? { ...box, temperature: temperatureInputs[boxId] } : box
        )
      }));
      setTemperatureInputs((prevInputs) => ({
        ...prevInputs,
        [boxId]: '' // Reset temperature input after submission
      }));
    }
  };

  const handleDeleteBox = (day, boxId) => {
    setLayouts((prevLayouts) => {
      const updatedLayouts = { ...prevLayouts };
      updatedLayouts[day] = updatedLayouts[day].filter((box) => box.i !== boxId);
  
      // Check if the deleted box is an empty box
      if (boxId.includes("empty-")) {
        Object.keys(updatedLayouts).forEach((dayKey) => {
          updatedLayouts[dayKey] = updatedLayouts[dayKey].filter((box) => !box.i.includes("empty-"));
        });
      }
  
      return updatedLayouts;
    });
  
    setTemperatureInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs };
      delete updatedInputs[boxId]; // Remove temperature input state for the deleted box
      return updatedInputs;
    });
  };
  

  const handleCopyClick = (day) => {
    setShowDropdown(day);
  };

  const handleCopySubmit = (event) => {
    event.preventDefault();
    if (copyTargetDay && showDropdown) {
      setLayouts((prevLayouts) => ({
        ...prevLayouts,
        [copyTargetDay]: [...prevLayouts[showDropdown]] // Copy the layout directly
      }));
    }
    setShowDropdown(null);
    setCopyTargetDay('');
  };

  const handleCheck = () => {
    setChecked(true);
    // Generate boxes for empty time slots
    Object.keys(layouts).forEach((day) => {
      const layout = layouts[day];
      const emptySlots = [];
      for (let y = 0; y < 24;y++) { // Loop through each y value up to 24
        const slot = layout.find((box) => box.y === y);
        if(slot){
          y=y+slot.h-1
        }
        if (!slot) {
          const nextBox = layout.find((box) => box.y > y);
          const height = nextBox ? nextBox.y - y : 24 - y; // Calculate height up to next box or end of day
          emptySlots.push({
            i: `empty-${day}-${y}`,
            x: 0,
            y: y,
            w: 1,
            h: height, // Set height to calculated value
            minW: 1,
            maxW: 2,
            minH: 1,
            maxH: 24,
            temperature: false
          });
          y = nextBox ? nextBox.y-1 : 25;
        }
      }
      setLayouts((prevLayouts) => ({
        ...prevLayouts,
        [day]: [...layout, ...emptySlots]
      }));
    });
  };
  const formatTime = (y) => {
    const hours = y;
    return `${hours.toString().padStart(2, '0')}:00`;
  };

  const timeLabels = Array.from({ length: 24 }, (_, index) => {
    const hours = index;
    return (
      <div key={index} style={{ height: `${rowHeight}px`, margin: '10px 0', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
        {`${hours.toString().padStart(2, '0')}:00`}
      </div>
    );
  });

  return (
    <div className=' px-10 pt-20'>
      <div style={{ display: 'flex', justifyContent: 'flex-start',gap:'70px',marginLeft:'60px', alignItems: 'center', marginBottom: '10px', zIndex:'1000' }}>
        {daysOfWeek.map((day) => (
          <div key={day} style={{ width: '150px', textAlign: 'center', position: 'relative', margin:'0 10px' }}>
            <div class="text-[#0BAAC9] bg-[#E7F9FD] font-medium rounded-lg text-sm px-3 py-2.5 me-2 mb-2 border border-[#0BAAC9] flex items-center justify-between ">
              {day}
              <button className=' text-[#111928] hover:text-white' onClick={() => handleCopyClick(day)}>
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M14 4v3a1 1 0 0 1-1 1h-3m4 10v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2m11-3v10a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V7.87a1 1 0 0 1 .24-.65l2.46-2.87a1 1 0 0 1 .76-.35H18a1 1 0 0 1 1 1Z"/>
                </svg>
              </button>  
            </div>
            {showDropdown === day && (
              <form onSubmit={handleCopySubmit} style={{ position: 'absolute', top: '40px', left: '0px', backgroundColor: 'white', padding: '10px', border: '1px solid #ccc', zIndex:'100' }}>
                <label>
                  Copy to:
                  <select value={copyTargetDay} onChange={(e) => setCopyTargetDay(e.target.value)}>
                    <option value="">Select Day</option>
                    {daysOfWeek.filter(d => d !== day).map((d) => (
                      <option key={d} value={d}>{d}</option>
                    ))}
                  </select>
                </label>
                <button type="submit">Submit</button>
              </form>
            )}
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', zIndex:'10' }}>
        <div style={{ width: '60px' }}>
          {timeLabels}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start', gap:'70px', width: '100%', position: 'relative', zIndex:'10' }} onClick={handleContainerClick}>
          <div className={`  absolute top-[22px] left-0 bottom-0 right-0 w-full h-full flex flex-col gap-[78px] z-10`}>
            {Array.from({ length: 24 }).map((_, index) => (
              <div key={index} className='w-full border-t-2 border-[#E8E8E8] border-dotted z-10'></div>
            ))}
          </div>

          {daysOfWeek.map((day) => (
            <div key={day} style={{ width: '150px', margin: '0 10px', position: 'relative', zIndex:'10' }}>
              <GridLayout
                className="layout pt-[18px] z-10"
                compactType={null}
                layout={layouts[day]}
                cols={1}
                rowHeight={rowHeight}
                width={150}
                draggableHandle=".box-drag"
                draggableCancel=".box-resize-handle"
                onLayoutChange={(newLayout) => onLayoutChange(day, newLayout)}
                onResizeStart={() => setIsResizingOrDragging(true)}
                onResizeStop={() => setIsResizingOrDragging(false)}
                onDragStart={() => setIsResizingOrDragging(true)}
                onDragStop={() => setIsResizingOrDragging(false)}
              >
                {layouts[day].map((box) => (
                  <div key={box.i} className="box" style={{ border: '1px solid #ccc', backgroundColor: '#f0f0f0', position: 'relative', zIndex:'10' }} onClick={(e) => e.stopPropagation()}>
                    <div style={{ padding: '0 10px', position: 'relative', zIndex:'1',fontSize:'12px' }}>
                    {box.temperature !== false ? (
                      // If box.temperature is null, render this:
                      box.temperature ? (
                        // If box.temperature has a value, display it with °C
                        `${box.temperature}°C`
                      ) : (
                        // Otherwise, render the input field
                        <input
                          type="text"
                          value={temperatureInputs[box.i] || ''}
                          onChange={(e) => handleTemperatureChange(e, box.i)}
                          onKeyDown={(e) => handleTemperatureKeyPress(e, box.i, day)}
                          placeholder="Enter temperature"
                          onClick={(e) => e.stopPropagation()} // Prevent click event on input from bubbling up
                          style={{ width: '100%', padding: '4px', fontSize: '10px', border: 'none' }}
                        />
                      )
                      
                    ) : (
                      // If box.temperature is not null, render nothing
                      <div>Fill in the fields</div>
                    )}

                      <button
                        style={{
                          position: 'absolute',
                          top: '0px',
                          right: '0px',
                          background: 'red',
                          color: 'white',
                          border: 'none',
                          borderRadius: '50%',
                          cursor: 'pointer',
                          width: '20px', // Adjusted for better visibility
                          height: '20px', // Adjusted for better visibility
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '0', // Ensuring no additional padding
                          fontSize: '12px', // Adjusted font size for better visibility
                          lineHeight: '14px' // Adjusted line height to center the 'x' better
                        }}
                        onClick={() => handleDeleteBox(day, box.i)}
                      >
                        x
                      </button>

                    </div>
                    <div
                      className="box-drag"
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        height: '20px',
                        cursor: 'ns-resize',
                        backgroundColor: '#ccc',
                        zIndex:'0'
                        // backgroundImage: `url(${sep})`,
                        // backgroundPosition: 'center bottom',
                        // backgroundRepeat: 'no-repeat',
                        // backgroundSize: 'contain'
                      }}
                    />
                  </div>
                ))}
              </GridLayout>
            </div>
          ))}
        </div>
      </div>
      <button onClick={handleCheck}>Check</button>
    </div>
  );
};

export default MyGridLayout;
