import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import MyGridLayout from './table'
import './index.css'

function App() {

  return (
    <>
      <Router>
        <Routes>

          <Route path='/' element={<MyGridLayout />}>
            {/* <Route index element={<MyGridLayout />} /> */}
          </Route>

        </Routes>
      </Router>
    </>
  )
}

export default App
